
.dictionary_terms {

    font-style: italic;
    font-size: $small-font-size;
    margin-top: 80px;

    .term {
        font-weight: bold;
    }

    .phonetic {
        color: $grey-color;
    }

    .definition {
        
    }
    
    .notes {
        color: $grey-color;
    }
}