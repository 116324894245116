
/* Image Styles */

.articleImage
{
  width: 100px;
  height: 100px;  
}

.articleShadowImage
{  
  border: 1px solid #fff;
  
  margin-top: 10px;
  margin-bottom: 10px;
  // max-width: 450px;
  
  background-color: #fff;  
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.4), inset 0 0 50px rgba(0, 0, 0, 0.1);  
  -moz-box-shadow: 0 0 4px rgba(0, 0, 0, 0.4), inset 0 0 50px rgba(0, 0, 0, 0.1);  
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4), inset 0 0 50px rgba(0, 0, 0, 0.1);   
  
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.articleCenterImage 
{
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.articlePolaroidImage
{  
  padding: 10px 10px 25px 10px;
  border: 1px solid #fff;
  
  margin-top: 10px;
  margin-bottom: 10px;
  // max-width: 450px;
  
  background-color: #fff;  
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.4), inset 0 0 50px rgba(0, 0, 0, 0.1);  
  -moz-box-shadow: 0 0 4px rgba(0, 0, 0, 0.4), inset 0 0 50px rgba(0, 0, 0, 0.1);  
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4), inset 0 0 50px rgba(0, 0, 0, 0.1);   
  
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.articleImageRHSPaperClip
{
  float: right;
  position: relative;
  margin-right: -268px;
  top: 60px;
  background: url("../images/content/profile/paperclip-rhs.png");
  
  width: 107px;
  height: 36px;
}


.articlePolaroidImageRHSPaperClip
{
  float: right;
  position: relative;
  margin-right: -290px;
  top: 60px;
  background: url("../images/content/profile/paperclip-rhs.png");
  
  width: 107px;
  height: 36px;
}

.articleImageLHSPaperClip
{
  float: left;
  position: relative;
  margin-left: -272px;
  top: 60px;
  background: url("../images/content/profile/paperclip-lhs.png");
  
  width: 107px;
  height: 36px;
}


.articlePolaroidImageLHSPaperClip
{
  float: left;
  position: relative;
  margin-left: -293px;
  top: 60px;
  background: url("../images/content/profile/paperclip-lhs.png");
  
  width: 107px;
  height: 36px;
}

.articleRightImage
{
    float: none;
    @include media-min-width($on-laptop) 
    {
        float: right;
    }

    position: relative;
    right: -12px;
    width: 225px;
    
    -webkit-transform: rotate(1deg);
    -moz-transform: rotate(1deg); 
}

.articleLeftImage
{
    float: none;
    @include media-min-width($on-laptop) 
    {
        float: left;
    }
    position: relative;
    left: -12px;
    width: 225px;
    
    -webkit-transform: rotate(-2deg);
    -moz-transform: rotate(-2deg); 
}

.articleRightPolaroidImage
{
  float: none;
  @include media-min-width($on-laptop) 
  {
      float: right;
  }

  position: relative;
  right: -12px;
  width: 225px;
  
  padding: 10px 10px 25px 10px;
  border: 1px solid #fff;
  
  background-color: #fff;  
  -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.5), inset 0 0 50px rgba(0, 0, 0, 0.1);  
  -moz-box-shadow: 0 0 6px rgba(0, 0, 0, 0.5), inset 0 0 50px rgba(0, 0, 0, 0.1);  
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.5), inset 0 0 50px rgba(0, 0, 0, 0.1);   
}

.articleLeftPolaroidImage
{
  float: none;
  @include media-min-width($on-laptop) 
  {
      float: left;
  }

  position: relative;
  left: -12px;
  width: 225px;
  
  padding: 10px 10px 25px 10px;
  border: 1px solid #fff;
  
  background-color: #fff;  
  -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.5), inset 0 0 50px rgba(0, 0, 0, 0.1);  
  -moz-box-shadow: 0 0 6px rgba(0, 0, 0, 0.5), inset 0 0 50px rgba(0, 0, 0, 0.1);  
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.5), inset 0 0 50px rgba(0, 0, 0, 0.1);   
}



.articleRightImageTilt
{
  -webkit-transform: none;
  -moz-transform: none; 
  transform: none;
   @include media-min-width($on-laptop) 
  {
      -webkit-transform: rotate(2deg);
      -moz-transform: rotate(2deg); 
      transform: rotate(2deg);
  }
   
}

.articleLeftImageTilt
{   
  -webkit-transform: none;
  -moz-transform: none; 
  transform: none;
   @include media-min-width($on-laptop) 
  {
      -webkit-transform: rotate(-2deg);
      -moz-transform: rotate(-2deg); 
      transform: rotate(-2deg); 
  } 
}

.thumbnail {
  cursor: pointer;
  max-height: 320px;
}

.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.50);
  text-align: center;
  cursor: pointer;
}

.modalContentWrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modalContainer img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  max-height: 90%;
}