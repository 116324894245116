
body
{
    //     background:
    // radial-gradient(black 15%, transparent 16%) 0 0,
    // radial-gradient(black 15%, transparent 16%) 8px 8px,
    // radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 0 1px,
    // radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 8px 9px;
    // background-color:#282828;
    // background-size:16px 16px;

    // background-color: #fff; 
    // background-image: 
    // linear-gradient(90deg, transparent 79px, #abced4 79px, #abced4 81px, transparent 81px),
    // linear-gradient(#eee .1em, transparent .1em);
    // background-size: 100% 1.2em;
}

a {
  code {
    color: $brand-color;
  }
}

.highlight
{
    position: absolute;
    left: 0;
    right: 0;

    
    border: 1px solid $grey-color-light;

    pre 
    {
        background: none;
        border: none;
        overflow-x: scroll;
        word-break: none;
        word-wrap: none;
        white-space: none;
        overflow: auto;
        word-wrap: normal;
        white-space: pre;

        code {

            overflow-wrap: normal;
            white-space: pre;

             @include media-query($on-palm) {
                font-size: 12px;
             }
        }

        max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
        max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));

        margin-right: auto;
        margin-left: auto;
        padding-right: $spacing-unit;
        padding-left: $spacing-unit;

        @include media-query($on-laptop) {
            max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
            max-width:         calc(#{$content-width} - (#{$spacing-unit}));
            padding-right: $spacing-unit / 2;
            padding-left: $spacing-unit / 2;
        }
    }
}

.higlight-placeholder
{
    margin: 10px 0px;
}


$body-container-padding: 10px;

.body-container
{
    display: table;
    position: static;
    width: 100%;
    padding: 0px $body-container-padding;
}

.highlight
{
    margin-left: -$body-container-padding;
    margin-right: -$body-container-padding;
    padding-left: $body-container-padding;
    padding-right: $body-container-padding;
}

.sidebar-container
{
    box-sizing: border-box;
    display: none;
    position: relative;
    width: 0px;
    @include media-min-width($on-laptop) 
    {
        width: 240px;
        min-width: 200px;
        display: table-cell;
    }
}

.page-content
{
    display: table-cell;
    position: relative;
}

.page-content 
{
    .wrapper
    {
        min-height: 600px;
    }
}

.twitter-feed
{
     display: none;
     @include media-min-width($on-laptop) 
     {
        position: sticky;
        position: -webkit-sticky;
        top: 20px;
        display: block;
        width: 100%;
        padding: 10px;
     }

      @include media-query($on-palm) 
     {

       
     }

   
}

.hidden-in-compact
{
    display: none;
    @include media-min-width($on-laptop) 
    {
      display: block;
    }
}

.inline-hidden-in-compact
{
    display: none;
    @include media-min-width($on-laptop) 
    {
      display: inline-block;
    }
}

.table-cell-hidden-in-compact
{
    display: none;
    @include media-min-width($on-laptop) 
    {
      display: table-cell;
    }
}

.sidebar-inner
{
    position: absolute;
    top: 0px;
    bottom: 0px;
}

/* Timeline */

.timeline {
  list-style: none;
  padding: 20px 0 20px;
  position: relative;
}

.timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 3px;
  background-color: #eee;
  left: 50%;
  margin-left: -1.5px;
}

.tldate {
  display: block;
  width: 200px;
  background: #414141;
  border: 3px solid #212121;
  color: #ededed;
  margin: 0 auto;
  padding: 3px 0;
  font-weight: bold;
  text-align: center;
  -webkit-box-shadow: 0 0 11px rgba(0,0,0,0.35);
}

.timeline li {
  margin-bottom: 25px;
  position: relative;
}

.timeline li:before, .timeline li:after {
  content: " ";
  display: table;
}
.timeline li:after {
  clear: both;
}
.timeline li:before, .timeline li:after {
  content: " ";
  display: table;
}

/** timeline panels **/
.timeline li .timeline-panel {
  width: 46%;
  float: left;
  background: #fff;
  border: 1px solid #d4d4d4;
  padding: 20px;
  position: relative;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
}

/** panel arrows **/
.timeline li .timeline-panel:before {
  position: absolute;
  top: 26px;
  right: -15px;
  display: inline-block;
  border-top: 15px solid transparent;
  border-left: 15px solid #ccc;
  border-right: 0 solid #ccc;
  border-bottom: 15px solid transparent;
  content: " ";
}

.timeline li .timeline-panel:after {
  position: absolute;
  top: 27px;
  right: -14px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 14px solid #fff;
  border-right: 0 solid #fff;
  border-bottom: 14px solid transparent;
  content: " ";
}
.timeline li .timeline-panel.noarrow:before, .timeline li .timeline-panel.noarrow:after {
  top:0;
  right:0;
  display: none;
  border: 0;
}

.timeline li.timeline-inverted .timeline-panel {
  float: right;
}

.timeline li.timeline-inverted .timeline-panel:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}

.timeline li.timeline-inverted .timeline-panel:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}


/** timeline circle icons **/
.timeline li .tl-circ {
  position: absolute;
  top: 23px;
  left: 50%;
  text-align: center;
  background: #6a8db3;
  color: #fff;
  width: 35px;
  height: 35px;
  line-height: 35px;
  margin-left: -16px;
  border: 3px solid #90acc7;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  z-index: 99999;
}

.timeline-image {
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.15);
}



/** timeline content **/

.tl-heading{

  h4, h3, h2, h1 , p
  {
    margin: 0;
  }

  h4 
  {
    color: $red-color;
  }
  margin-bottom: 5px;
}

.tl-body p, .tl-body ul {
  margin-bottom: 0;
}

.tl-body > p + p {
  margin-top: 5px;
}

/** media queries **/
@media (max-width: 991px) {
  .timeline li .timeline-panel {
    width: 44%;
  }
}

@media (max-width: 700px) {
  
  ul.timeline:before {
    left: 40px;
  }
  
  .tldate { width: 140px; }

  ul.timeline li .timeline-panel {
    width: calc(100% - 90px);
    width: -moz-calc(100% - 90px);
    width: -webkit-calc(100% - 90px);
  }

  ul.timeline li .tl-circ {
    top: 22px;
    left: 22px;
    margin-left: 0;

  }
  ul.timeline > li > .tldate {
    margin: 0;
  }

  ul.timeline > li > .timeline-panel {
    float: right;
  }

  ul.timeline > li > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }

  ul.timeline > li > .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }
}

.timeline h3 .glyphicon
{
    font-size: 75%;
}

.timeline .location
{
    text-align: right;
}


/* Tags */

.tag-brace 
{
  position: relative;
  font-family: courier;
  font-size: 1.3em;
  color: #69C;
}

.page-heading
{
  display: inline-block;
}

.page-heading span 
{
  vertical-align: baseline;
}

.tag-title
{
  font-size: 0.8em;
  font-style: italic;
  text-align: center;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 2px;
  padding-bottom: 2px;

  background-color: $red-color;
  color: white;

  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

a.label
{
  color: white;
}


article footer
{
  border-top: 1px solid $grey-color-light;
  padding-top: 10px;
}

.twitter-share
{
  margin-top: 10px;
}

.comments
{
  margin-top: 10px;
}

.tags
{
  margin-bottom: 5px;
}